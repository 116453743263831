import React from "react";
import { Link } from "react-scroll";

import { businessName, MENU_HEIGHT } from "../../utils/constants";
import { StyledScrollLink } from "../Home/styles";
import {
  BottomMenuContainer,
  FooterContainer,
  FooterMenuItem,
  BottomMenuContent,
} from "./styles";

const Footer = ({scrollToTop}) => {
  const year = new Date();
  const currentYear = year.getFullYear();

  return (
    <FooterContainer>
      <BottomMenuContent>
        <BottomMenuContainer>
          <FooterMenuItem>
            <StyledScrollLink onClick={scrollToTop}>Home</StyledScrollLink>
          </FooterMenuItem>
          |
          <FooterMenuItem>
            <StyledScrollLink
              to="about"
              spy={true}
              smooth={true}
              offset={-MENU_HEIGHT}
              duration={15}
            >
              Sobre Nosotros
            </StyledScrollLink>
          </FooterMenuItem>
          |
          <FooterMenuItem>
            <StyledScrollLink
              to="contact"
              spy={true}
              smooth={true}
              offset={-MENU_HEIGHT}
              duration={15}
            >
              Contacto
            </StyledScrollLink>
          </FooterMenuItem>
        </BottomMenuContainer>
        <span>
          &copy; {businessName} {currentYear}
        </span>
      </BottomMenuContent>
    </FooterContainer>
  );
};

export default Footer;
