import {animateScroll as scroll} from 'react-scroll';

import Menu from "../Menu";
import Carousel from "./Carousel";
import Contact from "./Contact";
import MainContent from "./MainContent";
import Footer from "../Footer/index";

import { HomeMainContainer } from "./styles";

const Home = () => {
  const scrollToTop = () => scroll.scrollToTop({duration: 15});
  return (
    <>
      <Menu scrollToTop={scrollToTop} />
      <Carousel />
      <HomeMainContainer>
        <MainContent />
      </HomeMainContainer>
      <Contact />
      <Footer scrollToTop={scrollToTop} />
    </>
  );
};

export default Home;
