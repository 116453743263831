import CustomMap from "./Map";
import React from "react";

import {
  Content,
  MiddleSection,
  ServiceContainer,
  AllServicesContainer,
  ServiceList,
  ServiceItem,
  StyledTitle,
} from "./styles";

import { businessName, services } from "../../../utils/constants";
import { Subtitle } from "../../../theme/styles";



const MainContent = () => {
  const servicesByRoom = Object.entries(services);

  return (
    <MiddleSection id="about">
      <StyledTitle >{businessName}</StyledTitle>
      <Content>
        <p>
          Casa Valle se encuentra entre la sierra del Sueve y las playas del
          municipio de Caravia, la Espasa, Arenal de Moris y la Beciella, entre
          otras, que discurren a lo largo del camino de Santiago. Ofrecemos una
          casa familiar, en un ambiente tranquilo con vistas a la sierra del
          Sueve, una de las cumbres más próximas al mar que se puede encontrar.
        </p>
        <p>
          Situada en un valle (de ahí su nombre) dentro de un paraje natural,
          proporcionando un buen clima todo el año. Dentro de casa hemos querido
          que sea como tu vivienda, disponiendo de todo tipo de comodidades,
          cómo se observa en las fotos. Consta de tres dormitorios, dos baños y
          un amplio salón, con cocina americana, con salida a un gran porche y
          zona de descanso, integrando un amplio jardín de 1.500 metros
          cuadrados con vistas al Sueve.
        </p>
        <p>
          La gastronomía y las rutas también son activos del lugar, pudiendo
          disfrutar de unos días dejando atrás la rutina, pudiendo conseguir el
          sosiego que uno desea. Para los amantes del golf, se dispone de la
          posibilidad de disfrutar del campo La Rasa de Berbes, cercano a la
          bonita playa de Vega. Disfrutando de las vistas de los Picos de Europa
          y la costa asturiana, mientras se disfruta jugando y de las propias
          instalaciones.
        </p>
      </Content>
      <StyledTitle>Servicios</StyledTitle>
      <Content>
        <AllServicesContainer>
          {React.Children.toArray(
            servicesByRoom?.map((service) => {
              const room =
                service[0].charAt(0).toUpperCase() +
                service[0].slice(1).toLocaleLowerCase();
              const serviceOffered = service[1];

              return (
                <ServiceContainer>
                  <Subtitle style={{marginBottom: '0'}}>{room}</Subtitle>
                  <ServiceList>
                    {React.Children.toArray(
                      serviceOffered.map((eachService) => (
                        <ServiceItem>{eachService}</ServiceItem>
                      ))
                    )}
                  </ServiceList>
                </ServiceContainer>
              );
            })
          )}
        </AllServicesContainer>
      </Content>
      <StyledTitle>Donde estamos</StyledTitle>
      <CustomMap/>
    </MiddleSection>
  );
};

export default MainContent;
