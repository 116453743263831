import styled from "styled-components";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Carousel } from "react-responsive-carousel";

export const TopSection = styled.section``;

export const StyledCarousel = styled(Carousel)`
  ul {
    padding-left: 0;
    text-align: center;
  }

  li.thumb {
    max-height: 55px;
    img {
      object-fit: cover;
    }
  }

  .slider-wrapper {
    img {
      max-height: 900px;
      object-fit: contain;
    }
  }

  .control-dots {
    bottom: -10px;
    display: none;
  }

  .control-next.control-arrow:before {
    right: -10px;
    margin: 0;
    border-left-color: black;
  }

  .control-prev.control-arrow:before {
    left: -10px;
    margin: 0;
    border-right-color: black;
  }

  .carousel .thumbs-wrapper {
    margin: 0 25px;
    overflow: hidden;
  }
`;
