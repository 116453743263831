import { Route, Routes } from "react-router-dom";

import LandingScreen from "./screens/Landing";

import { AppMainContainer } from "./AppStyles";

function App() {
  return (
    <AppMainContainer className="App">
      <Routes>
        <Route exact path="/" element={<LandingScreen/>} />
      </Routes>
    </AppMainContainer>
  );
}

export default App;
