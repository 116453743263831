import styled from "styled-components";

import { FlexDiv } from "../../theme/styles";
import { black, white, lightGrey, strongGrey } from "../../theme/colors";

export const FooterContainer = styled(FlexDiv)`
  background-color: ${black};
  justify-content: center;
  align-items: center;
  height: 10rem;
  font-size: 1rem;
  * {
    color: ${white};
  }

  span {
    text-align: center;
    color: ${strongGrey};
  }
`;

export const BottomMenuContent = styled(FlexDiv)`
  flex-direction: column;
  align-items: center;
  width: 25%;

  @media (max-width: 768px) {
    width: 75%;
  }
`;

export const BottomMenuContainer = styled(FlexDiv)`
  color: ${lightGrey};
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const FooterMenuItem = styled.h4`
  font-weight: 300;
`;
