import ContactForm from "./Form";

import { 
  BottomSection,
  Content 
} from "./styles";

import { SectionTitle } from "../../../theme/styles";

import { CONTACT_PHONE } from "../../../utils/constants";

const Contact = () => {
  return (
    <BottomSection id="contact">
      <SectionTitle>Contacto</SectionTitle>
      <ContactForm />
    </BottomSection>
  );
}; 

export default Contact;
