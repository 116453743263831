import {
    StyledMapContainer
  } from "./styles";

import { Marker } from 'react-leaflet/Marker'
import { TileLayer } from 'react-leaflet/TileLayer'
import { Popup } from 'react-leaflet/Popup'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


import icon from '../../../../assets/icons/marker-icon.png';
import iconShadow from '../../../../assets/icons/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const CustomMap = () => {
    const position = {
        coords: [43.45989539604304, -5.194723813689427],
        zoom: 13
    }

    return (
        <StyledMapContainer center={position.coords} zoom={position.zoom}>
            <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position.coords}>
                <Popup>
                   Casa Valle
                </Popup>
             </Marker>
        </StyledMapContainer>
    )
}

export default CustomMap;