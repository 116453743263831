import styled from "styled-components";

import { primaryFont } from "./theme/fonts";
import { white, black } from "./theme/colors";

export const AppMainContainer = styled.div`
  font-family: ${primaryFont};

  input,
  textarea {
    border: 1px solid;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    box-shadow: none !important;
    :-webkit-autofill {
      color: #fff !important;
    }
    outline: none;
  }

  ::-webkit-scrollbar {
    width: 4px;
    &-thumb {
      background: ${white};
      border-radius: 7px;
    }
    &-track {
      background: ${black};
    }
  }
`;
