import { useState, useRef } from "react";

import { useFormik } from "formik";
import emailjs from "@emailjs/browser";

import { validationSchema } from "./validations";

import {
  ErrorContainer,
  StyleFormField,
  StyledFormContainer,
  StyleFormLabel,
  StyledFormInput,
  StyledFormTextArea,
  FromStyledButton,
  StyledReCaptcha,
} from "./styles";
import { EMAIL_SEND_STATUS } from "../../../../utils/constants";

const ContactForm = () => {
  const [formSendStatus, setFormSendStatus] = useState(EMAIL_SEND_STATUS.SEND);
  const recaptchaRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      from_name: "",
      to_name: process.env.REACT_APP_MAIL_USER_ID, // email id of the admin
      reply_to: "",
      subject: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      const valuesToSend = {
        ...values,
        "g-recaptcha-response": captchaToken,
      };

      setFormSendStatus(EMAIL_SEND_STATUS.SENDING);
      emailjs
        .send(
          process.env.REACT_APP_MAIL_SERVICE,
          process.env.REACT_APP_MAIL_TEMPLATE_ID,
          valuesToSend,
          process.env.REACT_APP_MAIL_USER_ID
        )
        .then(() => {
          setFormSendStatus(EMAIL_SEND_STATUS.SENT);
          setTimeout(() => {
            resetForm();
            setFormSendStatus(EMAIL_SEND_STATUS.SEND);
          }, 2000);
        })
        .catch(() => {
          setFormSendStatus(EMAIL_SEND_STATUS.ERROR);
        });
    },
  });

  return (
    <StyledFormContainer formik={formik} onSubmit={formik.handleSubmit}>
      <StyleFormField>
        <StyleFormLabel>Email</StyleFormLabel>
        <StyledFormInput
          type="email"
          name="reply_to"
          placeholder="Tu email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.reply_to}
          error={formik.touched?.reply_to && formik.errors?.reply_to}
        />
        <ErrorContainer>
          {formik.errors.reply_to &&
            formik.touched.reply_to &&
            formik.errors.reply_to}
        </ErrorContainer>
      </StyleFormField>

      <StyleFormField>
        <StyleFormLabel>Nombre</StyleFormLabel>
        <StyledFormInput
          type="text"
          name="from_name"
          placeholder="Tu nombre"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.from_name}
          error={formik.touched?.from_name && formik.errors?.from_name}
        />
        <ErrorContainer>
          {formik.errors.from_name &&
            formik.touched.from_name &&
            formik.errors.from_name}
        </ErrorContainer>
      </StyleFormField>

      <StyleFormField>
        <StyleFormLabel>Asunto</StyleFormLabel>
        <StyledFormInput
          type="text"
          name="subject"
          placeholder="Indica el asunto"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.subject}
          error={formik.touched?.subject && formik.errors?.subject}
        />
        <ErrorContainer>
          {formik.errors.subject &&
            formik.touched.subject &&
            formik.errors.subject}
        </ErrorContainer>
      </StyleFormField>

      <StyleFormField>
        <StyleFormLabel>Descripción</StyleFormLabel>
        <StyledFormTextArea
          type="textarea"
          name="message"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
          error={formik.touched?.message && formik.errors?.message}
        />
        <ErrorContainer>
          {formik.errors.message &&
            formik.touched.message &&
            formik.errors.message}
        </ErrorContainer>
      </StyleFormField>

      <StyledReCaptcha
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA}
        size="invisible"
      />

      <FromStyledButton
        type="submit"
        disabled={!(formik.isValid && formik.dirty)}
      >
        {formSendStatus}
      </FromStyledButton>
    </StyledFormContainer>
  );
};

export default ContactForm;
