import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  from_name: Yup.string().required("Este campo es obligatorio"),
  reply_to: Yup.string()
    .email("El email debe tener un formato válido")
    .required("Este campo es obligatorio"),
  subject: Yup.string().required("Este campo es obligatorio"),
  message: Yup.string().required("Este campo es obligatorio"),
});
