import styled from "styled-components";

import Toolbar from "@mui/material/Toolbar";
import { FlexDiv } from "../../theme/styles";
import { white, black } from "../../theme/colors";
import { MENU_HEIGHT } from "../../utils/constants";

export const StyledTopBar = styled(Toolbar)`
  position: sticky !important;
  background-color: ${white};
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${white};
  height: ${MENU_HEIGHT}px;
  z-index: 999;
 
  * {
    cursor: pointer;
  }

  .contact__button {
    background-color: ${black};
    color: white;
    padding: 0.25rem 1rem;
    border-radius: 20px;
  }
`;

export const LogoContainer = styled(FlexDiv)``;

export const MenuContainer = styled(FlexDiv)`
  justify-content: flex-end;
  align-items: center;
  width: 70%;
  * {
    margin-left: 3rem;
  }

  @media (max-width: 640px) {
    align-items: center;
    width: 300px;
    * {
      margin-left: 1rem;
    }
  }
`;

export const LogoImage = styled.img`
  width: 5rem;
  height: 5rem;
`;
