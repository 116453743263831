import React from "react";

import { TopSection, StyledCarousel } from "./styles";

import { images } from "./images";

const CarouselBehaviour = {
  autoPlay: true,
  transitionTime: 500,
  stopOnHover: true,
  showStatus: false,
};

const Carousel = () => {
  return (
    <TopSection>
      <StyledCarousel {...CarouselBehaviour}>
        {React.Children.toArray(
          images?.map((src) => (
            <div>
              <img src={src} alt="" className="testimg" />
            </div>
          ))
        )}
      </StyledCarousel>
    </TopSection>
  );
};

export default Carousel;
