import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

import { red } from "../../../../theme/colors";

import { grey, white, black } from "../../../../theme/colors";

export const StyledFormContainer = styled.form`
  background-color: ${grey};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 4rem;
  width: 35%;

  @media (max-width: 768px) {
    padding: 2rem 5rem;
    width: 50%;
  }
`;

export const StyleFormField = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyleFormLabel = styled.label`
  margin-right: auto;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

export const StyledFormInput = styled.input`
  width: 20rem;
  background-color: ${white} !important;
  border: none !important;
  border-radius: 10px;
  min-height: 1.5rem;
  text-indent: 0.5rem;

`;

export const StyledFormTextArea = styled.textarea`
  width: 20rem;
  height: 10rem;
  font-family: arial;
  background-color: ${white} !important;
  border: none !important;
  border-radius: 10px;
  text-indent: 0.4rem;
  line-height: 2rem;
`;

export const ErrorContainer = styled.small`
  margin: 0.5rem auto 1rem;
  font-weight: 400;
  color: ${red};
`;

export const FromStyledButton = styled.button`
  border-radius: 20px;
  background-color: ${black};
  border: none;
  color: ${white};
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 0;
  width: 50%;
  ${props => props.disabled && "opacity: 20%"};
`;

export const StyledReCaptcha = styled(ReCAPTCHA)`
  margin-bottom: 25px;
`;