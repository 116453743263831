import { Link } from "react-scroll";

import { Title } from "../../theme/styles";

import {
  LogoContainer,
  StyledTopBar,
  LogoImage,
  MenuContainer,
} from "./styles";

import logo from "../../assets/icons/logo_casa_valle.jpeg";
import { MENU_HEIGHT } from "../../utils/constants";
import { StyledScrollLink } from "../Home/styles";

const Menu = ({scrollToTop}) => {
  return (
    <StyledTopBar>
      <LogoContainer>
        <LogoImage src={logo} alt="" onClick={scrollToTop} />
      </LogoContainer>
      <MenuContainer>
        <Title onClick={scrollToTop}>Home</Title>
        <Title>
          <StyledScrollLink
            to="about"
            spy={true}
            smooth={true}
            offset={-MENU_HEIGHT}
            duration={15}
          >
            Sobre Nosotros
          </StyledScrollLink>
        </Title>
        <Title>
          <StyledScrollLink
            to="contact"
            spy={true}
            smooth={true}
            offset={-MENU_HEIGHT}
            duration={15}
            className="contact__button"
          >
            Contacto
          </StyledScrollLink>
        </Title>
      </MenuContainer>
    </StyledTopBar>
  );
};

export default Menu;
