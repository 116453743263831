import styled from "styled-components";
import { FlexDiv } from "../../../theme/styles";

export const Content = styled(FlexDiv)`
  flex-direction: column;
  text-align: left;
  align-items: flex-start; 
  justify-content: flex-start;
  padding: 2% 30%;
  @media (max-width: 768px) {
    padding: 0 8% 2rem;
    align-items: flex-start;
  }
`;

export const BottomSection = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  padding: 2%;

  h1 {
    text-align: center;
  }
`;
