import styled from "styled-components";

import { black } from "./colors";

export const Title = styled.h2`
  color: ${black};
  font-weight: 400;

  .underline {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 0.95rem;
  }

  text-align: center;
`;

export const Subtitle = styled.h3`
  color: ${black};
  font-weight: 700;
`;

export const SectionTitle = styled.h1`
  color: ${black};
  font-size: 3rem;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: 400;
  }
`;


export const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => props?.flexDirectionColumn && "column"};
  align-items: ${(props) => props?.alignItems};
  align-self: ${(props) => props?.alignSelf};
  justify-content: ${(props) => props?.justifyContent};
  margin: ${(props) => props?.margin};
  padding: ${(props) => props?.padding};
  width: ${(props) => props?.width};

  img {
    margin: ${(props) => props?.imgMargin};
  }
`;