import mainImg from "../../../assets/images/Porsche.jpg";
import img1 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-1.jpg";
import img2 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-2.jpg";
import img4 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-4.jpg";
import img6 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-6.jpg";
import img7 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-7.jpg";
import img8 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-8.jpg";
import img10 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-10.jpg";
import img11 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-11.jpg";
import img12 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-12.jpg";
import img13 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-13.jpg";
import img16 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-16.jpg";
import img19 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-19.jpg";
import img20 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-20.jpg";
import img21 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-21.jpg";
import img25 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-25.jpg";
import img28 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-28.jpg";
import img29 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-29.jpg";
import img31 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-31.jpg";
import img32 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-32.jpg";
import img35 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-35.jpg";
import img36 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-36.jpg";
import img37 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-37.jpg";
import img38 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-38.jpg";
import img39 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-39.jpg";
import img40 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-40.jpg";
import img42 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-42.jpg";
import img44 from "../../../assets/images/vivienda_vacacional_caravia_web_meni_fotografia-44.jpg";

export const images = [
  mainImg,
  img1,
  img2,
  img4,
  img6,
  img7,
  img8,
  img10,
  img11,
  img12,
  img13,
  img16,
  img19,
  img20,
  img21,
  img25,
  img28,
  img29,
  img31,
  img32,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img42,
  img44,
];
