import styled from "styled-components";

import { grey } from "../../../theme/colors";
import { FlexDiv, Title } from "../../../theme/styles";

export const MiddleSection = styled.section`
  background-color: ${grey};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 2% 20%;
  @media (max-width: 768px) {
    padding: 0 8% 2rem;
    align-items: flex-start;
  }
`;

export const Content = styled(FlexDiv)`
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
`;

export const AllServicesContainer = styled(FlexDiv)`
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 5rem;
  }
`;

export const ServiceContainer = styled(FlexDiv)`
  flex-direction: column;
  width: 100%;
  width: 11rem;
  margin: 0 3rem;
  @media (max-width: 768px) {
    align-items: flex-start;
    margin: 0;
  }

  @media (max-width: 1024px) {
    width: 5rem;
  }
`;

export const ServiceList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const ServiceItem = styled.li`
  margin: 0.4rem 0;
`;

export const StyledTitle = styled(Title)`
  text-decoration: underline;
  @media (max-width: 768px) {
    margin-top: 2rem;
    margin-right: auto;
  }
`;
