import { Link } from "react-scroll";

import styled from "styled-components";
import { FlexDiv } from "../../theme/styles";

export const HomeMainContainer = styled(FlexDiv)`
  flex-direction: column;
`;

export const StyledScrollLink = styled(Link)`
    text-decoration: none;
    text-align: center;
`;
