export const businessName = "Casa Valle";

export const services = {
  ESTABLECIMIENTO: [
    "Calefacción",
    "WIFI",
    "Chimenea",
    "Cocina",
    "Lavavajillas",
    "Lavadora",
    "Secadora",
    "Salón con TV",
    "Servicio de limpieza",
    "Servicio de lavandería",
    "Aparcamiento",
  ],
  HABITACIÓN: [
    "Calefacción", 
    "WIFI",
    "Secador"
  ],
  COMPLEMENTARIOS: [
    "Jardín", 
    "Muebles de jardín", 
    "Barbacoa",
    "Cuna gratuita",
    "Juegos de mesa"
  ]
};

export const CONTACT_PHONE = "+34 618 35 75 17";

export const MENU_HEIGHT = 80;

export const EMAIL_SEND_STATUS = {
  SEND: "Enviar",
  SENDING: "Enviando...",
  SENT: "¡Mensaje enviado!",
  ERROR: "Error al enviar"
}
